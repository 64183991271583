import { default as _91_46_46_46slug_9321KQCtI9YwMeta } from "/app/app/pages/[...slug].vue?macro=true";
import { default as _91id_93FGg10AdDFjMeta } from "/app/app/pages/[view]/[id].vue?macro=true";
import { default as _91name_93sUj2e48dtDMeta } from "/app/app/pages/about/[name].vue?macro=true";
import { default as _91id_935HCBwZ8uJvMeta } from "/app/app/pages/calendar/[id].vue?macro=true";
import { default as indexM6mJRhIbwTMeta } from "/app/app/pages/index.vue?macro=true";
import { default as navigateiZMin9n9sSMeta } from "/app/app/pages/navigate.vue?macro=true";
import { default as next_46clientrzVVNBGMayMeta } from "/app/app/pages/next.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/app/node_modules/.pnpm/nuxt@3.13.0_@libsql+client@0.14.0_@parcel+watcher@2.5.0_@types+node@22.10.2_better-sqlite3@11_nr2ih7cjmwzlg5ahk4lclmplqy/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as searchwyeWPnHEGGMeta } from "/app/app/pages/search.vue?macro=true";
export default [
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    component: () => import("/app/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___de",
    path: "/:slug(.*)*",
    component: () => import("/app/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "view-id___en",
    path: "/en/:view()/:id()",
    meta: _91id_93FGg10AdDFjMeta || {},
    component: () => import("/app/app/pages/[view]/[id].vue").then(m => m.default || m)
  },
  {
    name: "view-id___de",
    path: "/:view()/:id()",
    meta: _91id_93FGg10AdDFjMeta || {},
    component: () => import("/app/app/pages/[view]/[id].vue").then(m => m.default || m)
  },
  {
    name: "about-name___en",
    path: "/en/about/:name()",
    component: () => import("/app/app/pages/about/[name].vue").then(m => m.default || m)
  },
  {
    name: "about-name___de",
    path: "/about/:name()",
    component: () => import("/app/app/pages/about/[name].vue").then(m => m.default || m)
  },
  {
    name: "calendar-id___en",
    path: "/en/calendar/:id()",
    component: () => import("/app/app/pages/calendar/[id].vue").then(m => m.default || m)
  },
  {
    name: "calendar-id___de",
    path: "/calendar/:id()",
    component: () => import("/app/app/pages/calendar/[id].vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/app/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/app/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "navigate___en",
    path: "/en/navigate",
    meta: navigateiZMin9n9sSMeta || {},
    component: () => import("/app/app/pages/navigate.vue").then(m => m.default || m)
  },
  {
    name: "navigate___de",
    path: "/navigate",
    meta: navigateiZMin9n9sSMeta || {},
    component: () => import("/app/app/pages/navigate.vue").then(m => m.default || m)
  },
  {
    name: "next___en",
    path: "/en/next",
    component: () => createClientPage(() => import("/app/app/pages/next.client.vue").then(m => m.default || m))
  },
  {
    name: "next___de",
    path: "/next",
    component: () => createClientPage(() => import("/app/app/pages/next.client.vue").then(m => m.default || m))
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/app/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/search",
    component: () => import("/app/app/pages/search.vue").then(m => m.default || m)
  }
]