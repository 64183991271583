import revive_payload_client_OZU2QCWfKm from "/app/node_modules/.pnpm/nuxt@3.13.0_@libsql+client@0.14.0_@parcel+watcher@2.5.0_@types+node@22.10.2_better-sqlite3@11_nr2ih7cjmwzlg5ahk4lclmplqy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_GUCztPngSC from "/app/node_modules/.pnpm/nuxt@3.13.0_@libsql+client@0.14.0_@parcel+watcher@2.5.0_@types+node@22.10.2_better-sqlite3@11_nr2ih7cjmwzlg5ahk4lclmplqy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_XcvxZfazQ2 from "/app/node_modules/.pnpm/nuxt@3.13.0_@libsql+client@0.14.0_@parcel+watcher@2.5.0_@types+node@22.10.2_better-sqlite3@11_nr2ih7cjmwzlg5ahk4lclmplqy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_U5nVOsFPKi from "/app/node_modules/.pnpm/nuxt@3.13.0_@libsql+client@0.14.0_@parcel+watcher@2.5.0_@types+node@22.10.2_better-sqlite3@11_nr2ih7cjmwzlg5ahk4lclmplqy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ar0F5pTg3W from "/app/node_modules/.pnpm/nuxt@3.13.0_@libsql+client@0.14.0_@parcel+watcher@2.5.0_@types+node@22.10.2_better-sqlite3@11_nr2ih7cjmwzlg5ahk4lclmplqy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_kX1OAsUCuf from "/app/node_modules/.pnpm/nuxt@3.13.0_@libsql+client@0.14.0_@parcel+watcher@2.5.0_@types+node@22.10.2_better-sqlite3@11_nr2ih7cjmwzlg5ahk4lclmplqy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_4SM4uF9xiU from "/app/node_modules/.pnpm/nuxt@3.13.0_@libsql+client@0.14.0_@parcel+watcher@2.5.0_@types+node@22.10.2_better-sqlite3@11_nr2ih7cjmwzlg5ahk4lclmplqy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_pvy0lc6X8B from "/app/node_modules/.pnpm/nuxt@3.13.0_@libsql+client@0.14.0_@parcel+watcher@2.5.0_@types+node@22.10.2_better-sqlite3@11_nr2ih7cjmwzlg5ahk4lclmplqy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_HpZWMCBLbA from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.29.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_rZ5cRSCcUy from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.1.3_@vue+compiler-dom@3.5.13_eslint@9.19.0_jiti@2.4.2__magicast@0.3.5_rollup@4_yc6d6arkspurhfgomfysazmwsy/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_EZhP481o9e from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.1.3_@vue+compiler-dom@3.5.13_eslint@9.19.0_jiti@2.4.2__magicast@0.3.5_rollup@4_yc6d6arkspurhfgomfysazmwsy/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_JmIce6KxmU from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.1.3_@vue+compiler-dom@3.5.13_eslint@9.19.0_jiti@2.4.2__magicast@0.3.5_rollup@4_yc6d6arkspurhfgomfysazmwsy/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import ssg_detect_tRvMOXf6gn from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.1.3_@vue+compiler-dom@3.5.13_eslint@9.19.0_jiti@2.4.2__magicast@0.3.5_rollup@4_yc6d6arkspurhfgomfysazmwsy/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_OZU2QCWfKm,
  unhead_GUCztPngSC,
  router_XcvxZfazQ2,
  payload_client_U5nVOsFPKi,
  navigation_repaint_client_ar0F5pTg3W,
  check_outdated_build_client_kX1OAsUCuf,
  chunk_reload_client_4SM4uF9xiU,
  components_plugin_KR1HBZs4kY,
  prefetch_client_pvy0lc6X8B,
  plugin_client_HpZWMCBLbA,
  switch_locale_path_ssr_rZ5cRSCcUy,
  route_locale_detect_EZhP481o9e,
  i18n_JmIce6KxmU,
  ssg_detect_tRvMOXf6gn
]